import React, { useEffect } from 'react'

import { useFormik } from 'formik'
import {
    Button,
    Stack,
    Box,
    InputLabel,
    FormControl,
    InputBase,
    FormHelperText,
    Typography,
    FormControlLabel,
    Switch,
} from '@mui/material'
import { useMediaQuery } from '@mui/system'
import { useSearchParams } from 'react-router-dom'

import {
    ClubInformationFormValues,
    ClubInformationFormScheme,
} from './index.schema'
import { Form } from 'components/common'
import { AddressType, KeyValType } from 'api/generated'
import { LocationField, MultipleAutocompleteField } from 'components/inputs'
import { FormDataState } from 'components/pages/user/CreateClubPage'

interface ClubInformationFormProps {
    interests: KeyValType[]
    onSubmit: (values: ClubInformationFormValues) => void
    data: FormDataState
}

const ClubInformationForm = ({
    interests = [],
    onSubmit,
    data,
}: ClubInformationFormProps): JSX.Element => {
    const lessThan1000px = useMediaQuery('(max-width:1000px)')

    const [searchParams] = useSearchParams()
    const clubId = searchParams.get('clubId')
    const formik = useFormik({
        initialValues: {
            name: data.name || '',
            description: data.description || '',
            interests: data.interests || [],
            address: data.address || null,
            gradeFrom: data.gradeFrom || null,
            gradeTo: data.gradeTo || null,
            restrictedByGrade: !!(data.gradeTo && data.gradeFrom),
        },
        validationSchema: ClubInformationFormScheme,
        validateOnChange: true,
        onSubmit,
    })

    useEffect(() => {
        formik.setValues({
            name: data.name || '',
            description: data.description || '',
            interests: data.interests || [],
            address: data.address || null,
            gradeFrom: data.gradeFrom || null,
            gradeTo: data.gradeTo || null,
            restrictedByGrade: !!(data.gradeTo && data.gradeFrom),
        })
    }, [data])

    const interestOptions =
        interests?.map(s => ({
            value: s?.key as number,
            label: s?.val as string,
        })) || []

    return (
        <Box sx={{ maxWidth: lessThan1000px ? '100%' : '50%' }}>
            <Form onSubmit={formik.handleSubmit}>
                <Stack gap="24px">
                    <Stack gap="16px">
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="name">
                                Club Name{' '}
                                <Typography variant="caption" color="#CE321E">
                                    *
                                </Typography>
                            </InputLabel>
                            <InputBase
                                autoFocus
                                id="name"
                                fullWidth
                                name="name"
                                placeholder="Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.name &&
                                    Boolean(formik.errors.name)
                                }
                            />
                            {formik.touched.name && formik.errors.name && (
                                <FormHelperText>
                                    {formik.errors.name}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Stack gap="8px">
                            <FormControl variant="standard">
                                <InputLabel shrink htmlFor="description">
                                    Description{' '}
                                    <Typography
                                        variant="caption"
                                        color="#CE321E"
                                    >
                                        *
                                    </Typography>
                                </InputLabel>
                                <InputBase
                                    multiline
                                    minRows={6}
                                    maxRows={10}
                                    id="description"
                                    fullWidth
                                    name="description"
                                    placeholder="Description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.description &&
                                        Boolean(formik.errors.description)
                                    }
                                />
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent={
                                        formik.touched.description &&
                                        formik.errors.description
                                            ? 'space-between'
                                            : 'flex-end'
                                    }
                                >
                                    {formik.touched.description &&
                                        formik.errors.description && (
                                            <Typography
                                                textAlign="right"
                                                fontSize="12px"
                                                lineHeight="15px"
                                                color={
                                                    formik.touched
                                                        .description &&
                                                    formik.errors.description
                                                        ? 'error'
                                                        : '#000'
                                                }
                                            >
                                                {formik.errors.description}
                                            </Typography>
                                        )}
                                    <Typography
                                        textAlign="right"
                                        fontSize="12px"
                                        lineHeight="15px"
                                        color={
                                            formik.touched.description &&
                                            formik.errors.description
                                                ? 'error'
                                                : '#000'
                                        }
                                    >
                                        {formik.values.description.length}/1000
                                    </Typography>
                                </Stack>
                            </FormControl>
                        </Stack>
                        <MultipleAutocompleteField
                            limitTags={lessThan1000px ? 3 : 5}
                            withSelectAll
                            value={
                                interestOptions?.filter(i => {
                                    return (
                                        formik.values.interests as number[]
                                    )?.includes(i?.value as number)
                                }) || []
                            }
                            options={interestOptions || []}
                            label="Interests"
                            placeholder="Select"
                            state={{
                                errors: formik.errors.interests,
                                touched: formik.touched.interests,
                            }}
                            onChange={value =>
                                formik.setFieldValue('interests', value)
                            }
                        />
                        <FormControl variant="standard">
                            {((clubId && formik.values.address) || !clubId) && (
                                <LocationField
                                    onChange={details => {
                                        formik.setFieldValue('address', details)
                                    }}
                                    defaultValue={
                                        formik.values.address as AddressType
                                    }
                                    error={formik.errors.address}
                                    touched={formik.touched.address}
                                />
                            )}
                        </FormControl>
                        <FormControlLabel
                            sx={{
                                color: '#74747b',
                                margin: '15px 0',
                            }}
                            control={
                                <Switch
                                    value={formik.values.restrictedByGrade}
                                    checked={formik.values.restrictedByGrade}
                                    name="restrictedByGrade"
                                    onChange={formik.handleChange}
                                />
                            }
                            label="Grade restriction"
                        />
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            gap="16px"
                        >
                            <FormControl variant="standard" fullWidth>
                                <InputLabel shrink htmlFor="gradeFrom">
                                    Eligibility (grade)
                                </InputLabel>
                                <InputBase
                                    disabled={!formik.values.restrictedByGrade}
                                    id="gradeFrom"
                                    fullWidth
                                    name="gradeFrom"
                                    placeholder="From"
                                    value={formik.values.gradeFrom}
                                    onChange={formik.handleChange}
                                    type="number"
                                    inputProps={{
                                        min: 9,
                                    }}
                                    error={
                                        formik.touched.gradeFrom &&
                                        Boolean(formik.errors.gradeFrom)
                                    }
                                />
                                {formik.touched.gradeFrom &&
                                    formik.errors.gradeFrom && (
                                        <FormHelperText>
                                            {formik.errors.gradeFrom}
                                        </FormHelperText>
                                    )}
                            </FormControl>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel shrink htmlFor="gradeTo">
                                    Eligibility (grade)
                                </InputLabel>
                                <InputBase
                                    disabled={!formik.values.restrictedByGrade}
                                    id="gradeTo"
                                    fullWidth
                                    name="gradeTo"
                                    placeholder="To"
                                    value={formik.values.gradeTo}
                                    onChange={formik.handleChange}
                                    type="number"
                                    error={
                                        formik.touched.gradeTo &&
                                        Boolean(formik.errors.gradeTo)
                                    }
                                />
                                {formik.touched.gradeTo &&
                                    formik.errors.gradeTo && (
                                        <FormHelperText>
                                            {formik.errors.gradeTo}
                                        </FormHelperText>
                                    )}
                            </FormControl>
                        </Stack>
                    </Stack>
                    <Button type="submit">Next</Button>
                </Stack>
            </Form>
        </Box>
    )
}

export default ClubInformationForm
